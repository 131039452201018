import { KeyboardEvent } from "react";

export type useKeyBoardEventHandlerArgs = {
  keyboardKeys: KeyboardEvent<HTMLInputElement>["key"][];
  onKeyEvent: (key: useKeyBoardEventHandlerArgs["keyboardKeys"][0]) => void;
};

export const useKeyBoardEventHandler = (args: useKeyBoardEventHandlerArgs) => {
  const handleKeysPressEvent = (e: React.KeyboardEvent<HTMLElement>) => {
    e.stopPropagation();
    if (args.keyboardKeys.includes(e.key)) {
      args.onKeyEvent(e.key);
    }
  };

  return {
    handleKeysPressEvent,
  };
};
