import i18n from "assets/configi18n/i18n";
import { isEmpty } from "lodash";

export const precisionRound = (value: number, precision: number = 0) => {
  const multiplier = Math.pow(10, precision);
  return Math.round(value * multiplier) / multiplier;
};

export const toFixedRounded = (value: number, precision: number) => {
  return precisionRound(value, precision).toFixed(precision);
};

export const formatCurrency = (
  value: number,
  currency = "CAD",
  maxPrecision: number = 0,
  minPrecision?: number
) => {
  return new Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency,
    minimumFractionDigits: minPrecision,
    maximumFractionDigits: maxPrecision,
  }).format(value);
};

export const checkIsNaNOrSetDefault = (value: any, defaultValue: any = 1) => {
  return isNaN(value) ? defaultValue : value;
};

export const checkIsEmptyOrSetDefault = (value: any, defaultValue: any = 1) => {
  return isEmpty(value) ? defaultValue : value;
};

export const formatDecimalNumber = (value: string | number, fractionDigits: number = 2): number => {
  let formattedValue: number;
  if (typeof value === "string") {
    formattedValue = parseFloat(Number(value.replace(",", ".")).toFixed(fractionDigits));
  } else if (typeof value === "number") {
    formattedValue = parseFloat(value.toFixed(fractionDigits));
  }

  return formattedValue || 0;
};
