import React, { useEffect } from "react";
import AppDrawer from "./partials/app-drawer.container";
import { useLocation } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { frontendUrl } from "../urls";
import { history } from "../helpers/history";
import ChatDialog from "../components/inbox/chat-dialog.container";
import Nps from "../components/nps/nps.component";
import { useTranslation } from "react-i18next";
import { modalsActions } from "../services/modals/modals.actions";
import querystring from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { inboxActions } from "../services/inbox/inbox.actions";
import UserSurvey from "../components/user-survey/user-survey";
import { getIsUserSurveyModalOpen } from "../services/modals/modals.selectors";
import { profileNextActions } from "@next/modules/profile/redux";
import { setAmplitudeUserId } from "@next/utils/amplitudeUtils";
import ConfirmDialog from "@next/modals/shared-confirm-dialog";
import { SharedModals } from "@next/modals/shared-modals";
import { APP_ENVIRONMENT, checkAppEnvironmentIs } from "@next/utils/constantUtils";
import { initHotjar } from "@next/utils/browserUtils";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";
import { useHomePageLink } from "@next/hooks/useHomePageLink";
import { Box, CircularProgress } from "@mui/material";
import { CompanyTypeNext } from "@next/redux/types";
import AppBarNext from "@next/modules/app/components/app-bar-next/app-bar-next";
import { CustomBreadcrumbsBar } from "@next/components/custom-breadcrumbs-bar/custom-breadcrumbs-bars";
import { OnboardingPopup } from "@next/components/onboarding-checklist-popup/onboarding-checklist-popup";

/**
 * TODO: Update App layout routes with proper solution
 */
function AppLayout({ children, routeConfig, ...rest }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const currentRoute = location.pathname;
  const profile = rest.profile;
  const { token, company } = profile;
  const validAppEnvironment = checkAppEnvironmentIs([
    APP_ENVIRONMENT.DEMO,
    APP_ENVIRONMENT.PRODUCTION,
  ]);
  const allowedRoles = routeConfig?.allowedRoles ?? [];
  const hasCompanyRole = useHasCompanyRole(allowedRoles);
  const homePageLink = useHomePageLink();

  const customLayoutPadding = routeConfig?.customLayoutPadding ?? false;

  // NPS modal state
  const isNPSOpen = useSelector((state) => state?.modals?.isNPSOpen ?? false);
  const isUserSurveyModalOpen = useSelector(getIsUserSurveyModalOpen);
  const queryParams = querystring.parse(location.search.replace("?", ""));

  useEffect(() => {
    if (!profile.token) {
      return history.push(
        (queryParams.fromPortal === "true" ? `${frontendUrl.portal}/login/` : frontendUrl.login) +
          `?redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`
      );
    }

    // Fetch the company if available
    rest.fetchCompany();
    //Fetch user profile
    rest.fetchUserProfile();
    // Fetch user profile preferences
    rest.fetchProfilePreferences();
    if (validAppEnvironment && company?.type === "DO") {
      // Init Hotjar Tracking
      initHotjar(profile);
    }

    //Fetches the Marketplace Quotes for current Company
    if (company !== null && company !== "null") {
      setAmplitudeUserId(profile?.user?.email);
      rest.fetchPartConfigurationData();
      dispatch(profileNextActions.fetchSubscriptionListRequest());

      rest.initSocketConnection();
      rest.handleOpenModal(false, "isInboxOpen");
      rest.handleOpenModal(false, "isChatOpen");

      dispatch(inboxActions.getInboxMessages(1));

      rest.listenForUpdates(token, `${profile?.company?.id}`);

      rest.onReceiveUpdates(rest.handleInboxUpdates);

      if (queryParams.message) {
        dispatch(inboxActions.setMinimizeChatWindow());
        rest.handleOpenModal(true, "isChatOpen");
        rest.getQuotationThreadDetails(token, queryParams.message).then((data) => {
          rest.setActiveQuotationThread(null, null);

          dispatch(inboxActions.setMinimizeChatWindow());
          rest.setActiveQuotationThread(token, data);
        });
      }
    }
  }, []);

  // open NPS modal when nps_token is not null
  useEffect(() => {
    dispatch(
      profile?.user?.nps_token
        ? modalsActions.showModal(true, "isNPSOpen")
        : modalsActions.hideModal("isNPSOpen")
    );
  }, [profile?.user?.nps_token]);

  if (profile && profile.company === false) {
    return <Redirect to={frontendUrl.logout} />;
  }

  // User does not have a company yet, display the registration page
  // from where he can either join or create a company.
  if (profile.company && !profile.company.id) {
    return;
  }
  // User has a company in a pending state, display the company profile page
  if (profile.company && profile.company.id && !profile.company.is_active) {
    const permittedRoutes = [
      frontendUrl.userProfile,
      frontendUrl.settings,
      frontendUrl.changeUserPassword,
      frontendUrl.editUserProfile,
      frontendUrl.isEmailSubscrib,
      frontendUrl.editCompany,
    ];

    const isRoutePermitted = permittedRoutes.includes(currentRoute);
    // Make sure to always redirect the company profile page
    // if 1) The route is not permitted, not in the list above.
    //    2) The current route is not the company profile page
    if (!isRoutePermitted && location.pathname !== frontendUrl.companyProfile) {
      return <Redirect to={frontendUrl.companyProfile} />;
    }
  }

  if (!profile?.user?.user_type)
    return (
      <Box width="100vw" height="100vh" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );

  // only allow this route if the user has a role present in allowedRoles array
  // if no roles are specified, then any role will be allowed
  if (company?.type === CompanyTypeNext.DO && allowedRoles.length && !hasCompanyRole) {
    return <Redirect to={location.state?.fallback === "home" ? homePageLink : "/404"} />;
  }

  if (company?.type === CompanyTypeNext.DO) {
    if (company?.is_requests_enabled === false && currentRoute.includes(frontendUrl.buyerQuotes)) {
      return <Redirect from={currentRoute} to={frontendUrl.dashboard} />;
    }
    if (
      company?.is_order_management_enabled === false &&
      currentRoute.includes(frontendUrl.orders)
    ) {
      return <Redirect from={currentRoute} to={frontendUrl.dashboard} />;
    }
    if (company?.is_pm_feature_enabled === false && currentRoute.includes(frontendUrl.projects)) {
      return <Redirect from={currentRoute} to={frontendUrl.dashboard} />;
    }
  }

  return (
    <>
      {isUserSurveyModalOpen && (
        <div>
          <section className="app-floating-appbar">
            <UserSurvey
              questionLabel={t("userSurvey:questionLabel")}
              yesLabel={t("userSurvey:yesLabel")}
              maybeLabel={t("userSurvey:maybeLabel")}
              noLabel={t("userSurvey:noLabel")}
              noToastMessage={t("userSurvey:noToastMessage")}
              yesToastMessage={t("userSurvey:yesToastMessage")}
              maybeToastMessage={t("userSurvey:maybeToastMessage")}
              name="supplier-production-capacity-outsourcing"
            />
          </section>
        </div>
      )}

      {isNPSOpen && (
        <section className="app-floating-appbar">
          <Nps />
        </section>
      )}

      {!rest.isHideAppBar ? <AppBarNext isHideNav={rest.isHideNav} /> : null}

      <CustomBreadcrumbsBar />

      <div
        className={`app-root ${isNPSOpen ? "open-nps" : ""} ${
          isUserSurveyModalOpen ? "open-user-survey" : ""
        }`}
      >
        <Box p={customLayoutPadding || "36px"} width="100%">
          {children}
        </Box>
      </div>

      <AppDrawer />
      <ChatDialog />
      <ConfirmDialog />
      <OnboardingPopup />
      {/* Shared Modals */}
      <SharedModals />
    </>
  );
}

export default AppLayout;
