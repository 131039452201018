import { formatDecimalNumber } from "@next/utils/numberUtils";
import { ChangeEventHandler, useState } from "react";
import { useKeyBoardEventHandler } from "./useKeyBoardEventHandler";

export type UseDecimalInputArgs = {
  value: number;
  onChange: (value: number) => void;
  onBlur?: () => void;
  defaultDigits?: number;
};

export const useDecimalInput = ({
  value,
  onChange,
  onBlur,
  defaultDigits = 2,
}: UseDecimalInputArgs) => {
  const [_value, _setValue] = useState<string>(() => value.toString());

  const { handleKeysPressEvent: handleEnterPressEvent } = useKeyBoardEventHandler({
    keyboardKeys: ["Enter"],
    onKeyEvent: () => {
      onChange(formatDecimalNumber(_value, defaultDigits));
      onBlur?.();
    },
  });
  const { handleKeysPressEvent: handleArrowsKeysPressEvent } = useKeyBoardEventHandler({
    keyboardKeys: ["ArrowDown", "ArrowUp"],
    onKeyEvent: (key) => {
      const formattedValue = formatDecimalNumber(_value, 0);
      const newValue = formattedValue + (key === "ArrowDown" ? -1 : 1);
      if (newValue > 0) {
        onChange(newValue);
        handleSetValue(newValue.toString());
      }
    },
  });

  const handleSetValue = (value: string) => {
    const newValue = value?.replaceAll(INVALID_DECIMAL_TEXT_REGEX, "") || "0";
    _setValue(newValue.length > 1 && newValue[0] === "0" ? newValue.slice(1) : newValue);
  };
  const _handleChangeValue: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    handleSetValue(e.target.value);
  };

  const handleBlur = () => {
    onChange(formatDecimalNumber(_value));
    onBlur?.();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    handleEnterPressEvent(e);
    handleArrowsKeysPressEvent(e);
  };

  const handleFocus = () => {
    _setValue(value.toString());
  };

  return {
    _handleChangeValue,
    handleEnterPressEvent,
    handleArrowsKeysPressEvent,
    handleBlur,
    handleKeyDown,
    handleFocus,
    _value,
  };
};

const INVALID_DECIMAL_TEXT_REGEX = /[^0-9.,]/g;
