import { TextField, TextFieldProps } from "@mui/material";
import { UseDecimalInputArgs, useDecimalInput } from "@next/hooks/useDecimalInput";
import React, { FC } from "react";

export type TableDecimalInputProps = UseDecimalInputArgs & {
  textFieldProps?: Partial<TextFieldProps>;
};

export const TableDecimalInput: FC<TableDecimalInputProps> = ({ textFieldProps, ...props }) => {
  const { _value, _handleChangeValue, handleBlur, handleKeyDown, handleFocus } =
    useDecimalInput(props);
  return (
    <TextField
      value={_value}
      onChange={_handleChangeValue}
      type="text"
      onBlur={handleBlur}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      {...DEFAULT_TEXT_INPUT_PROPS}
      {...textFieldProps}
    />
  );
};

const DEFAULT_TEXT_INPUT_PROPS: TextFieldProps = {
  variant: "standard",
  size: "small",
  inputProps: { style: { textAlign: "left" } },
  InputProps: {
    disableUnderline: true,
  },
  autoFocus: true,
  fullWidth: true,
};

export const TABLE_DECIMAL_INPUT_FORMATTERS = {};
