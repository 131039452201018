import { Box, Typography, styled } from "@mui/material";

export const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
});

export const StyledBtnBox = styled(Box)({
  width: "218px",
});

export const StyledInnerBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  gap: "16px",
  alignItems: "center",
});

export const StyledTypography = styled(Typography)({
  lineHeight: "20px",
  letterSpacing: "0.25px",
  fontFamily: "Roboto",
});

export const StyledTableWrapper = styled(Box)(() => ({
  margin: "0",
  overflowX: "auto",
}));
